import { Auction, Item, User } from '@/api';
import * as ics from 'ics';
import moment from 'moment';
import { saveAs } from 'file-saver';
import i18next from 'i18n';

export const setUpCalendarReminder = (auction: Auction, item: Item, user?: User | null) => {
    const startDate = new Date(auction.start_date);
    const year = startDate.getFullYear();
    const month = startDate.getMonth();
    const day = startDate.getDate();
    const hour = startDate.getHours();
    const minutes = startDate.getMinutes();

    const endDate = moment(auction.end_date);
    const duration = moment.duration(endDate.diff(moment(auction.start_date)));
    const durationHours = Math.floor(duration.asMinutes() / 60);
    const durationMinutes = duration.asMinutes() % 60;
    const event = {
        start: [year, month, day, hour, minutes],
        duration: { hours: durationHours, minutes: durationMinutes },
        title: auction.type === Auction.TypeEnum.Auction ? i18next.t('remind-me.auction-title', { name: item.name }) : i18next.t('remind-me.sale-title', { name: item.name }),
        description: auction.type === Auction.TypeEnum.Auction ? i18next.t('remind-me.auction-description', { name: item.name, date: new Date(auction.start_date).toLocaleString() }) : i18next.t('remind-me.sale-description', { name: item.name, date: new Date(auction.start_date).toLocaleString() }),
        url: `${process.env.NEXT_PUBLIC_URL}/item/${item.slug}`,
        status: 'CONFIRMED',
        busyStatus: 'BUSY',
        organizer: { name: 'The Realest', email: 'info@therealest.com' },
        attendees: [
            user ? {
                name: `${user.first_name} ${user.last_name}`,
                email: user.email_address,
                rsvp: true,
                partstat: 'ACCEPTED',
                role: 'REQ-PARTICIPANT',
            } : undefined
        ].filter(Boolean)
    };

    ics.createEvent(event as ics.EventAttributes, (error, value) => {
        if (error) {
            return;
        }

        const blob = new Blob([value], { type: 'text/calendar' });
        saveAs(blob, `${event.title}.ics`);
    });
};
