'use client';

import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Item } from '@/api';
import useAuction from '@/hooks/useAuction';
import { usePathname, useRouter } from 'next/navigation';
import { useFavorite } from '@/hooks/api/useFavorite';
import useAuth from '@/contexts/AuthContext';
import useUnAuth from '@/contexts/UnAuthContext';
import { useMobile } from '@/hooks/useMobile';
import { getPoster } from '@/app/utils/item-utils';
import ProductTileBidSection from '@/components/ecommerce/ProductTile/ProductTileBidSection';
import ProductTileImage from '@/components/ecommerce/ProductTile/ProductTileImage';
import ProductTileTitle from '@/components/ecommerce/ProductTile/ProductTileTitle';
import ProductTileTypeTitle from '@/components/ecommerce/ProductTile/ProductTileTypeTitle';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ShareModal from '../ShareModal';
import ArtistTitle from '../../general/ArtistTitle';

const ProductTile = ({ fullWidth, data }: { fullWidth?: boolean, data: Item }) => {
    const isMobile = useMobile();
    const firstImage = useMemo(() => getPoster(data.images), [data]);
    const router = useRouter();
    const pathname = usePathname();
    const { add, remove, isFavorite } = useFavorite();
    const { user } = useAuth();
    const [showShare, setShowShare] = useState<boolean>(false);
    const { showSignUpOrLogin } = useUnAuth();

    const { hasAuction, auctionOver, auctionType, comingSoon, auction, endTime } = useAuction(data);

    return (
        <>
            <div className={clsx('group/item', { 'w-full': fullWidth, 'mx-[16px]': !fullWidth })} key={`ProductTile${data.id}`}>
                <div className="relative h-0 overflow-hidden pb-[100%] w-[100%]">
                    <div className="absolute bottom-0 right-0 hidden justify-end items-center w-[72px] p-[12px] hover-hover:flex z-20">
                        <div className="flex flex-row items-center justify-center transition-opacity opacity-0 group-hover/item:opacity-100 bg-white rounded-full w-[48px] h-[48px]" data-tooltip-id="tooltip-kyc">
                            <ReactTooltip
                                id="tooltip-kyc"
                                place="top"
                                content={!isFavorite(data.id) ? 'Save To Favorites' : 'Remove From Favorites'}
                            />
                            <img
                                alt="TheRealest"
                                className={clsx('w-[22px] h-[22px] cursor-pointer')}
                                src={!isFavorite(data.id) ? '/assets/icons/like-icon.svg' : '/assets/icons/like-icon-full.svg'}
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    if (!user) {
                                        if (isMobile) {
                                            router.push(`/mobile/signup?redirect=${pathname}`);
                                        } else {
                                            showSignUpOrLogin('signup');
                                        }
                                    } else if (isFavorite(data.id)) {
                                        remove(data.id);
                                    } else {
                                        add(data.id);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <ProductTileImage data={data} user={user} firstImage={firstImage} secondImage={data.images && data.images.length > 0 ? data.images[1] : undefined} />
                </div>
                <div className="py-[16px]">
                    <div className="gap-[8px] mb-[20px]">
                        <ProductTileTypeTitle auctionType={auctionType} comingSoon={comingSoon} auctionOver={auctionOver} item={data} />
                        <ProductTileTitle data={data} user={user} />
                        <ArtistTitle artist={data.artists?.[0]} variant={0} />
                    </div>
                    <div>
                        <ProductTileBidSection auction={auction} hasAuction={hasAuction} endTime={endTime} comingSoon={comingSoon} auctionOver={auctionOver} item={data} />
                    </div>
                </div>

            </div>
            <ShareModal product={data} show={showShare && !isMobile} onHide={() => setShowShare(false)} />
        </>
    );
};

export default ProductTile;
