import clsx from 'clsx';
import i18next from 'i18next';
import React from 'react';

export type IProductBadgeProps = {
    type: 'trending';
    theme?: 'purple' | 'normal' | 'black';
    className?: string;
};

const ProductBadge = (props: IProductBadgeProps) => {
    const { type, className, theme = 'normal' } = props;
    const getThemeColors = () => {
        switch (theme) {
            case 'normal':
                return {
                    fill: '#ffffff',
                    background: 'rgba(0,0,0, 0.25)'
                };
            case 'purple':
                return {
                    fill: '#6941C6',
                    background: '#D0D5DD'
                };
            case 'black':
                return {
                    fill: '#000000',
                    background: 'rgba(208, 213, 221, 0.25)'
                };
            default:
                break;
        }
        return {};
    };
    if (type === 'trending') {
        return (
            <div style={{ background: getThemeColors()?.background }} className={clsx('rounded-full w-fit px-3 py-[1px] flex justify-center items-center', className)}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 14.6668C10.745 14.6668 13 12.4915 13 9.6995C13 9.01416 12.965 8.2795 12.585 7.1375C12.205 5.9955 12.1287 5.84816 11.727 5.14283C11.5553 6.58183 10.637 7.18183 10.4037 7.36116C10.4037 7.1745 9.84833 5.11216 9.006 3.87783C8.179 2.66683 7.05433 1.8725 6.395 1.3335C6.395 2.35683 6.10733 3.87816 5.695 4.6535C5.283 5.4285 5.20567 5.45683 4.69067 6.0335C4.176 6.61016 3.93967 6.7885 3.509 7.4885C3.07867 8.1885 3 9.12083 3 9.80616C3 12.5982 5.255 14.6668 8 14.6668Z" stroke={getThemeColors()?.fill} strokeWidth="1.6" strokeLinejoin="round" />
                </svg>
                <span className="ml-1 font-heading capitalize text-2sm" style={{ color: getThemeColors()?.fill }}>{i18next.t('common:general.trending')}</span>
            </div>
        );
    }
    return null;
};

export default ProductBadge;
