import React, { useMemo } from 'react';
import BCSpacer from 'public/assets/icons/chevron-right.svg';
import clsx from 'clsx';
import { Item } from '@/api';
import Link from 'next/link';
import Skeleton from 'react-loading-skeleton';
import i18next from 'i18n';

type IProductBreadCrumbsProps = {
    className?: string;
    item?: Item
    isLoading?: boolean;
};

const ProductBreadCrumbs = (props: IProductBreadCrumbsProps) => {
    const { className, item, isLoading } = props;

    const defaultQuery = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const q: any = {};
        if (item?.partner) {
            q.partners = item.partner.name;
        }
        return q;
    }, [item?.partner]);

    return (
        <div className={clsx('justify-center md:justify-start items-center text-sm font-[500] text-blue8 font-secondary mb-4', className)}>
            {isLoading ? <Skeleton width={280} height={18} /> : (
                <>
                    {!item?.partner && (
                        <div className="inline-block">
                            <Link prefetch={false} href={{ pathname: '/search', query: defaultQuery }}>{i18next.t('general.all')}</Link>
                        </div>
                    )}
                    {item?.partner && (
                        <div className="inline-block">
                            <Link prefetch={false} href={{ pathname: '/search', query: defaultQuery }}>{item.partner.name}</Link>
                        </div>
                    )}
                    <div className="inline-block md:px-2 px-1">
                        <BCSpacer className="pt-[2px] md:pt-0" />
                    </div>
                    <div className="inline-block">
                        <Link prefetch={false} href={{ pathname: `/category/${item?.category.slug}`, query: defaultQuery }}><span>{item?.category.name}</span></Link>
                    </div>
                    <div className="inline-block md:px-2 px-1">
                        <BCSpacer className="pt-[2px] md:pt-0" />
                    </div>
                    <div className="inline-block">
                        <Link prefetch={false} href={{ pathname: `/category/${item?.category.slug}`, query: { ...defaultQuery, sub_categories: item?.sub_category.slug } }}><span>{item?.sub_category.name}</span></Link>
                    </div>
                </>
            )}
        </div>
    );
};

export default ProductBreadCrumbs;
