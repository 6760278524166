import { Item, User, ItemImage } from '@/api';
import SmallLogo from '@/components/general/LogoSmall';
import { ResponsiveImage } from '@/components/general/ResponsiveImage';
import { isTouchDevice } from '@/helpers/TouchDevice';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useState } from 'react';

const ProductTileImage = ({ data, user, firstImage, secondImage }: { data: Item; user: User | null; firstImage: ItemImage | undefined; secondImage: ItemImage | undefined }) => {
    const [showSecond, setShowSecond] = useState<boolean>(false);

    return (
        <Link prefetch={false} className="no-hover" href={`/item/${data.slug}`}>
            {(data.private && !user) ?
                <SmallLogo className="w-[324px] h-[324px] p-12 [&>path]:fill-blue2" /> :
                <div onMouseEnter={() => (!isTouchDevice() && secondImage) && setShowSecond(true)} onMouseLeave={() => (!isTouchDevice() && secondImage) && setShowSecond(false)} className="relative w-full h-auto">
                    <motion.div
                        animate={{
                            opacity: secondImage && showSecond ? 0 : 1
                        }}>
                        <ResponsiveImage
                            imageWidth={324}
                            alt=""
                            className="w-full h-auto object-cover font-secondary rounded-[4px]"
                            style={{ background: '#F5F5F5' }}
                            src={firstImage?.url}
                        />
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{
                            opacity: secondImage && showSecond ? 1 : 0
                        }}>
                        {secondImage?.type === ItemImage.TypeEnum.Video ?
                            <video preload="auto" autoPlay playsInline controls={false} loop muted src={secondImage.url} className="w-full h-auto object-cover font-secondary absolute inset-0 rounded-[4px] self-center" /> :
                            <ResponsiveImage
                                imageWidth={324}
                                alt=""
                                className="w-full h-auto object-cover font-secondary absolute inset-0 rounded-[4px]"
                                style={{ background: '#F5F5F5' }}
                                src={secondImage?.url}
                            />}
                    </motion.div>

                </div>
            }
        </Link>
    );
};

export default ProductTileImage;
