import { Auction, Item } from '@/api';
import clsx from 'clsx';
import i18next from 'i18next';
import Link from 'next/link';

const ProductTileTypeTitle = ({ auctionType, comingSoon, auctionOver, item }: { auctionType: Auction.TypeEnum | undefined; comingSoon: { startTime: Date; } | undefined; auctionOver: boolean, item: Item }) => {
    if (item.check_offers_possible) {
        return <Link prefetch={false} className="no-hover" href={`/item/${item.slug}`}><span className={clsx('font-[500] text-[14px] tracking-[-0.2px] text-[#884FD1]')}>{i18next.t('common:product-tile.make-offer')}</span></Link>;
    }

    switch (auctionType) {
        case Auction.TypeEnum.Auction:
            return <Link prefetch={false} className="no-hover" href={`/item/${item.slug}`}><span className={clsx('font-[500] text-[14px] tracking-[-0.2px]', (comingSoon || auctionOver) ? 'text-grey15' : 'text-green4')}>{auctionOver ? i18next.t('common:product-tile.auction-ended') : i18next.t('common:product-tile.auction')}</span></Link>;
        case Auction.TypeEnum.BuyNow:
            return <Link prefetch={false} className="no-hover" href={`/item/${item.slug}`}><span className={clsx('font-[500] text-[14px] tracking-[-0.2px] text-[#3069FE]')}>{i18next.t('common:product-tile.buy-now')}</span></Link>;
        default:
            return null;
    }
};
export default ProductTileTypeTitle;
