'use client';

import { createContext, useContext, useMemo } from 'react';

export interface UnAuthContextType {
    showSignUpOrLogin: (mode: 'login' | 'signup') => void
}

const UnAuthContext = createContext<UnAuthContextType>({
    showSignUpOrLogin: (mode: 'login' | 'signup') => {},
});

export const UnAuthProvider = ({ children, showSignUpOrLogin }: { children: React.ReactNode; } & UnAuthContextType) => {
    const value = useMemo(() => {
        return { showSignUpOrLogin };
    }, [showSignUpOrLogin]);
    return <UnAuthContext.Provider value={value}>{children}</UnAuthContext.Provider>;
};

export const useUnAuth = () => {
    return useContext(UnAuthContext);
};

export default useUnAuth;
